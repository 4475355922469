import { DatoJsonField } from "src/types";
import { useSiteSettings } from "./hooks";
import { fromEntries, isRecord, truthy } from "./utils";

export const availableFlags = {
	"portal-create-claim": false,
	"portal-dynamic-factors": false,
	"portal-cancellation-feedback": false,
	"refactored-menu": false,
};

type FeatureFlag = keyof typeof availableFlags;

export type FeatureFlags = Record<FeatureFlag, boolean>;

export const useFeatureFlag = (flag: FeatureFlag) => {
	const { featureFlags } = useSiteSettings();

	const flags = {
		...featureFlags,
		...getFeatureFlagOverrides(),
	};

	return flags[flag];
};

const getFeatureFlagOverrides = () => {
	let featureFlagOverrides = {};

	try {
		featureFlagOverrides = process.env.OVERRIDE_FEATURE_FLAGS
			? JSON.parse(process.env.OVERRIDE_FEATURE_FLAGS)
			: {};
	} catch {
		// do nothing
	}

	return featureFlagOverrides;
};

export const sanitizeFeatureFlagsConfig = (
	json: DatoJsonField,
): FeatureFlags => {
	if (!isRecord(json)) {
		throw new Error(
			"FeatureFlags from CMS are invalid?" + JSON.stringify(json),
		);
	}

	const availableFlagsKeys = Object.keys(availableFlags);

	const filtered = fromEntries(
		Object.entries(json)
			.map(([k, v]) => {
				if (availableFlagsKeys.includes(k) && typeof v === "boolean") {
					return [k, v] as [string, boolean];
				}

				return null;
			})
			.filter(truthy),
	);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return {
		...availableFlags,
		...filtered,
	} as FeatureFlags;
};

// 🔬 jest unit tested
